// const BASE_URL = "http://127.0.0.1:5003/node_api";
// const BASE_URL = "https://adminomar.iossmlm.com/node_api";
const BASE_URL = "https://admin.naturasupplement.com/node_api";
// const BASE_URL = "https://api.infinitemlmsoftware.com/";
const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const BASE_URL = "http://192.168.9.47:5002/";
// const BASE_URL = "http://192.168.9.47:5002/";

const PAYPAL_CLIENT_ID =
    "Ad077-Gx8st GcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75";

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID };
